import axios from './http'

// 待审核数据列表
const getPageList = (params) => axios.get('/ParkingLotApi/RecordExamine/GetPageList', { params: params })

// 获取待审核数据详情
const getDetail = (params) => axios.get('/ParkingLotApi/RecordExamine/GetDetail', { params: params })

// 数据审核
const examineRecord = (data) => axios.post('/ParkingLotApi/RecordExamine/ExamineRecord', data)
export default {
  getPageList,
  getDetail,
  examineRecord
}
