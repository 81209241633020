<template>
  <!-- 出入记录审核 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <lebo-query :isMultiRow="true" @toggleChange="toggleChange" class="queryBox">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-row>
          <el-col :span="24">
            <el-form-item label="停车场：">
              <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
                placeholder="请选择停车场" @change="onSubmit()">
                <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
                  :value="item.parking_id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车位编号：">
              <el-input maxlength="15" placeholder="请输入车位编号" v-model="queryForm.parking_space_name"
                @input="(e) => (queryForm.parking_space_name = validSpace(e))"
                @keyup.enter.native="onSubmit()"></el-input>
            </el-form-item>
            <el-form-item label="设备名称：">
              <el-input maxlength="30" placeholder="请输入设备名称" v-model="queryForm.device_name"
                @input="(e) => (queryForm.device_name = validSpace(e))" @keyup.enter.native="onSubmit()"></el-input>
            </el-form-item>
            <el-form-item label="状态：">
              <el-select popper-class="my-select" clearable v-model="queryForm.examine_status" placeholder="请选择"
                @clear="queryForm.examine_status = null" @change="onSubmit()">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="!isMultiRow">
              <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="isMultiRow">
          <el-col :span="24">
            <el-form-item label="时间：">
              <el-date-picker size="small" v-model="time_range" value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
                start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item v-if="isMultiRow">
              <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </lebo-query>
    <le-card title="出入记录审核">
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
          @current-change="currentChange">
          <template slot-scope="{row}" slot="examine_status">
            <span>{{ typeName(row.examine_status) }}</span>
          </template>
          <template slot-scope="{row}" slot="update_time">
            <span>{{ validDateTime(row.update_time) }}</span>
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="setting" icon="bianji" hint="审核" @click="checkData(row)" v-if="row.examine_status === 1">
            </lbButton>
            <lbButton type="succes" icon="chakan" hint="查看" @click="lookDetails(row)" v-if="row.examine_status !== 1">
            </lbButton>
          </template>
        </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column prop="parking_space_name" label="车位编号"></el-table-column>
          <el-table-column prop="plate_number" label="车牌号"></el-table-column>
          <el-table-column prop="device_name" label="设备名称"></el-table-column>
          <el-table-column prop="abnormal_desc" label="详情">
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span>{{ typeName(scope.row.examine_status) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              <span>{{ validDateTime(scope.row.update_time) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="setting" icon="shenhe" hint="审核" @click="checkData(scope.row)"
                v-if="scope.row.examine_status === 1"></lbButton>
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookDetails(scope.row)"
                v-if="scope.row.examine_status !== 1"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="TotalCount">
        </el-pagination>
      </div>
    </le-card>
    <lebo-dialog :title="titleDialog" :isShow="lookdialogVisible" width="55%" @close="lookdialogVisible = false" footerSlot closeOnClickModal>
      <div class="dialog-content">
        <div class="dialog-left">
          <span>车辆入场抓拍图片</span>
          <el-image :z-index="9000" :src="getFirstImage(detailItem.go_in_image)"
            :preview-src-list="formatImage(detailItem.go_in_image)">
            <div slot="error" class="image-slot">
              <span>暂无图片</span>
            </div>
          </el-image>
          <el-image :z-index="9000" :src="secondImage(detailItem.go_in_image)"
            :preview-src-list="formatImage(detailItem.go_in_image)">
            <div slot="error" class="image-slot">
              <span>暂无图片</span>
            </div>
          </el-image>
        </div>
        <div class="dialog-middle">
          <span>车辆出场抓拍图片</span>
          <el-image :z-index="9000" :src="getFirstImage(detailItem.go_out_image)"
            :preview-src-list="formatImage(detailItem.go_out_image)">
            <div slot="error" class="image-slot">
              <span>暂无图片</span>
            </div>
          </el-image>
          <el-image :z-index="9000" :src="secondImage(detailItem.go_out_image)"
            :preview-src-list="formatImage(detailItem.go_out_image)">
            <div slot="error" class="image-slot">
              <span>暂无图片</span>
            </div>
          </el-image>
        </div>
        <div class="dialog-right">
          <div class="dialog-item">
            <span class="left">停车场：</span>
            <span class="right">{{ detailItem.parking_name }}</span>
          </div>
          <div class="dialog-item">
            <span class="left">车位编号：</span>
            <span class="right">{{ detailItem.parking_space_name }}</span>
          </div>
          <div class="dialog-item">
            <span class="left">设备名称：</span>
            <span class="right">{{ detailItem.device_name }}</span>
          </div>
          <div class="dialog-item">
            <span class="left">车牌号：</span>
            <span v-if="!isEdit" class="right">{{ detailItem.plate_number }}</span>
            <el-input class="right" maxlength="8" v-model="postData.plate_number"
              @input="(e) => (postData.plate_number = validSpace(e))" style="width:100%" v-if="isEdit"
              placeholder="请输入车牌号"></el-input>
          </div>
          <div class="dialog-item">
            <span class="left">车牌颜色：</span>
            <span v-if="!isEdit" class="right">{{ getWayName('ColorType', detailItem.color_type) }}</span>
            <el-select class="right" v-if="isEdit" popper-class="my-select" clearable v-model="postData.color_type"
              placeholder="请选择车牌颜色">
              <el-option v-for="item in getOptions('ColorType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="dialog-item">
            <span class="left">出入场时间：</span>
            <span v-if="!isEdit" class="right">{{ validDateTime(detailItem.go_in_time) }}至{{
              validDateTime(detailItem.go_out_time) }}</span>
            <el-date-picker class="right" v-if="isEdit" size="small" v-model="dialog_time_range"
              value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" type="datetimerange"
              range-separator="至" start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
            </el-date-picker>
          </div>
          <div class="dialog-item">
            <span class="left">异常类型：</span>
            <span v-if="!isEdit" class="right">{{ detailItem.abnormal_text }}</span>
            <el-select class="right" v-if="isEdit" popper-class="my-select" clearable filterable allow-create
              default-first-option v-model="postData.abnormal_text" placeholder="请选择或输入异常类型">
              <el-option v-for="item in abnormalOptions" :key="item.value" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" v-if="!isEdit" @click="lookdialogVisible = false">返 回</lbButton> -->
        <lbButton type="goBack" icon="back" v-if="isEdit" @click="giveUp">作 废</lbButton>
        <lbButton type="orign" icon="confirm" v-if="isEdit" @click="paseGo">通 过</lbButton>
      </span>
    </lebo-dialog>
  </div>
</template>

<script>
import request from '@/api/datacheck'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  data () {
    return {
      time_range: '',
      dialog_time_range: '',
      options: [],
      lookdialogVisible: false,
      titleDialog: '',
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        parking_id: '',
        parking_space_name: '', // 车位编号
        device_name: '',
        examine_status: '',
        go_in_time: '',
        go_out_time: ''
      },
      // 表格数据
      tableData: [
        {
        }
      ],
      TotalCount: null,
      typeOptions: [
        {
          value: 1,
          name: '待审核'
        },
        {
          value: 2,
          name: '审核通过'
        },
        {
          value: 3,
          name: '已作废'
        }
      ],
      detailItem: {},
      postData: {
        _id: '', // 审核数据id
        plate_number: '', // 车牌号
        color_type: '', // 车牌颜色
        go_in_time: '', // 入场时间
        go_out_time: '', // 出场时间
        examine_status: '', // 审核状态
        abnormal_text: null // 异常类型 -文本 不填默认给 ""
      },
      abnormalOptions: [
        {
          value: 1,
          name: '无异常'
        },
        {
          value: 2,
          name: '出入场车牌不一致'
        },
        {
          value: 3,
          name: '图片缺失'
        }
      ],
      isEdit: false,
      isMultiRow: true, // 查询下拉弹窗
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '车位编号',
            prop: 'parking_space_name'
          }, {
            label: '车牌号',
            prop: 'plate_number'
          }, {
            label: '设备名称',
            prop: 'device_name'
          }, {
            label: '详情',
            prop: 'abnormal_desc'
          }, {
            label: '状态',
            prop: 'examine_status',
            slot: true
          }, {
            label: '创建时间',
            prop: 'update_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    console.log('created---')
  },
  watch: {
    'time_range' (newV, oldV) {
      if (newV) {
        this.queryForm.go_in_time = newV[0]
        this.queryForm.go_out_time = newV[1]
      } else {
        this.queryForm.go_in_time = ''
        this.queryForm.go_out_time = ''
        this.onSubmit()
      }
    },
    'dialog_time_range' (newV, oldV) {
      console.log('=-======dialog_time_range=', newV)
      if (newV) {
        this.postData.go_in_time = newV[0]
        this.postData.go_out_time = newV[1]
      } else {
        this.postData.go_in_time = ''
        this.postData.go_out_time = ''
      }
    }
  },
  activated () {
    console.log('activatedactivated')
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  mounted () {
    this.requestPorkingList()
    this.requestResult(this.queryForm)
  },
  methods: {
    ...mapActions(['requestPorkingList']),
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestResult(this.queryForm)
    },
    querySearch (queryString, cb) {
      var restaurants = this.options
      // console.log(restaurants);
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      // console.log('results----', results);
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        // console.log(restaurant);
        return (restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1)
      }
    },
    // 停车场列表
    // 记录查询
    async requestResult (params) {
      var obj = this.deepClone(params)
      if (obj.examine_status === '') {
        delete obj.examine_status
      }
      try {
        const res = await request.getPageList(obj)
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
        this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      } catch (error) { }
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestResult(this.queryForm)
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestResult(this.queryForm)
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestResult(this.queryForm)
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestResult(this.queryForm)
    // },
    typeName (status) {
      switch (status) {
        case 1:
          return '待审核'
        case 2:
          return '审核通过'
        case 3:
          return '已作废'
      }
    },
    // 获取第二张图片
    secondImage (imagstr) {
      if (imagstr) {
        var newArray = imagstr.split(',')
        var newimageStr = ''
        if (newArray.length >= 2) {
          newimageStr = newArray[1]
        }
        return newimageStr
      }
      return ''
    },
    // 查看
    async lookDetails (row) {
      this.isEdit = false
      this.detailItem = row
      this.lookdialogVisible = true
      this.titleDialog = '查看详情'
      // const res = await request.getDetail({ _id: row._id });
      // if (res.Code === 200) {
      //   this.detailItem = res.Data;
      //   this.lookdialogVisible = true;
      // }
    },
    // 审核
    checkData (row) {
      this.isEdit = true
      this.detailItem = row
      this.lookdialogVisible = true
      this.titleDialog = '审核'
      const { _id, plate_number, color_type, go_in_time, go_out_time, examine_status, abnormal_text } = row
      console.log(row)
      this.postData = {
        _id: _id, // 审核数据id
        plate_number: plate_number, // 车牌号
        color_type: color_type, // 车牌颜色
        go_in_time: go_in_time, // 入场时间
        go_out_time: go_out_time, // 出场时间
        examine_status: examine_status, // 审核状态
        abnormal_text: abnormal_text // 异常类型 -文本 不填默认给 ""
      }
      if (go_in_time === '0001-01-01T00:00:00+08:05' && go_out_time !== '0001-01-01T00:00:00+08:05') {
        this.dialog_time_range = [new Date(), new Date(go_out_time)]
      } else if (go_out_time === '0001-01-01T00:00:00+08:05' && go_in_time !== '0001-01-01T00:00:00+08:05') {
        this.dialog_time_range = [new Date(go_in_time), new Date()]
      } else {
        this.dialog_time_range = [new Date(go_in_time), new Date(go_out_time)]
      }
    },
    async giveUp () {
      if (!this.validPlate(this.postData.plate_number)) {
        this.$message('请输入正确的车牌号码')
        return
      } else if (this.postData.color_type === '') {
        this.$message('请选择车牌颜色')
        return
      } else if (this.postData.go_in_time === '' || this.postData.go_out_time === '') {
        this.$message('请选择出入场时间')
        return
      } else if (this.postData.abnormal_text === '') {
        this.$message('请选择或输入异常类型')
        return
      }
      this.postData.examine_status = 3
      await request.examineRecord(this.postData)
      this.lookdialogVisible = false
      this.requestResult(this.queryForm)
    },
    async paseGo () {
      if (!this.validPlate(this.postData.plate_number)) {
        this.$message('请输入正确的车牌号码')
        return
      } else if (this.postData.color_type === '') {
        this.$message('请选择车牌颜色')
        return
      } else if (this.postData.go_in_time === '' || this.postData.go_out_time === '') {
        this.$message('请选择出入场时间')
        return
      } else if (this.postData.abnormal_text === '') {
        this.$message('请选择或输入异常类型')
        return
      }
      this.postData.examine_status = 2
      await request.examineRecord(this.postData)
      this.lookdialogVisible = false
      this.requestResult(this.queryForm)
    },
    // 下拉查询
    toggleChange (flag) {
      // console.log(flag)
      this.isMultiRow = flag
    }
  },
  beforeRouteLeave (to, from, next) {
    next()
  }
}
</script>

<style scoped lang="less">
.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 500px;
  }
}

.dialog-content {
  display: flex;
  height: 400px;

  .dialog-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-right: 5px;

    .el-image {
      flex: 1;
      margin-top: 5px;
      z-index: 2004;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.2);

      .image-slot {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .dialog-middle {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: center;

    .el-image {
      flex: 1;
      margin-top: 5px;
      z-index: 2004;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.2);

      .image-slot {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .dialog-right {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: space-between;

    .dialog-item {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .left {
        display: flex;
        flex: 2;
        flex-direction: row;
        justify-content: flex-end;
      }

      .right {
        margin-left: 20px;
        display: flex;
        flex: 7;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }
}

.queryBox {
  margin-bottom: 20px;
}
// 列表最大高度
/deep/.el-table .el-table__body-wrapper{
  max-height: 500px !important;
}
</style>
